import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import './../HomeTranslate'
import { useTranslation } from 'react-i18next';
import {INFO} from "../../../_helpers/Constants";
import call000 from "../../../_assets/images/call000.svg";
import email from "../../../_assets/images/email000.svg";

const Carta = () => {
    const { t } = useTranslation();
    const defaultState = {
        center: [51.088389355789005, 71.41418830589112],
        zoom: 15,
      };
    return (
        <section className="carta" id='contacts'>
            <div className='carta-map'>
                <YMaps>
                    <Map defaultState={defaultState} style={{ width: '100%', height: '100%' }}>
                        <Placemark geometry={[51.088389355789005, 71.41418830589112]} />
                    </Map>
                </YMaps>
            </div>
            <div className="carta-content">
                <p className='carta-content_title'>{t('carta_title')}</p>
                <p className='carta-content_address'>{t('carta_address')}</p>
                <p className='carta-content_city'>{t('carta_city')}</p>
                <p className="carta-content_tel">
                    <a href={`tel:${INFO.tel}`} >
                        {INFO.tel}
                    </a>
                </p>
                <p className="carta-content_email">
                    <a href={`mailto:${INFO.email}`} >
                        {INFO.email}
                    </a>
                </p>
            </div>
        </section>
    )
};

export default Carta;