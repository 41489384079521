import { addTranslation } from '../../_helpers/translate';

addTranslation({
  ru: {
    insert_title: 'Разработка IT-решений для бизнеса',
    insert_text: 'Мы стремимся создавать инновационные технологические решения, которые делают жизнь легче и более продуктивной для наших клиентов',
    insert_btn: 'Свяжитесь с нами',
    about_title: 'О нас',
    about_text2: 'Добро пожаловать в byteGenix software – ваш надежный партнер в мире технологий! Мы предлагаем комплексные решения в области разработки и технологий, чтобы помочь вашему бизнесу процветать и расти.',
    about_lists: 'Наша экспертиза включает в себя:',
    about_list: 'Разработка мобильных приложений',
    about_list2: 'Веб-разработка',
    about_list3: 'Искусственный интеллект',
    about_list4: 'Поддержка крупных проектов',
    about_list5: 'Десктопные приложения',
    carta_title: 'Адрес',
    questions_title: 'Остались вопросы?',
    questions_text: 'Если у вас остались вопросы или вам необходима дополнительная информация, наши специалисты готовы на них ответить.',
    questions_btn: 'свяжитесь с нами',
    carta_address: 'Мангилик Ел, С4.6 Astana Hub',
    carta_city: 'Астана, Казахстан',
    work_title: 'Как мы работаем',
    work_content1Title: 'Консультация и планирование',
    work_content1Text: 'Начнем с внимательного прослушивания ваших потребностей и целей, чтобы разработать оптимальное решение.',
    work_content2Title: 'Дизайн и разработка',
    work_content2Text: 'Наши эксперты начнут работу над вашим проектом, создавая уникальный и современный дизайн.',
    work_content3Title: 'Тестирование и оптимизация',
    work_content3Text: 'Мы тщательно проверим каждый аспект вашего проекта, чтобы гарантировать его выдающуюся производительность',
    work_content4Title: 'Запуск и поддержка',
    work_content4Text: 'Мы сопровождаем ваш проект после запуска, обеспечивая его надежную работу и обновления.',
    what_title: 'Почему выбирают нас',
    what_content1Title: 'Индивидуальный подход',
    what_content1Text: 'Мы понимаем, что каждый проект уникален, и работаем над ним внимательно, чтобы достичь ваших целей.',
    what_content2Title: 'Экспертная команда',
    what_content2Text: 'Наша команда состоит из опытных разработчиков и дизайнеров, готовых воплотить в жизнь ваши идеи.',
    what_content3Title: 'Современный дизайн',
    what_content3Text: 'Мы создаем стильные и функциональные решения, которые выделяют вас среди конкурентов.',
    what_content4Title: 'SEO-оптимизация',
    what_content4Text: 'Мы заботимся о том, чтобы ваш веб-проект был легко находимым в поисковых системах.',
    what_content5Title: 'Поддержка и обслуживание',
    what_content5Text: 'Мы не просто создаем проекты, но и обеспечиваем их надежную работу и поддержку. Не упустите шанс улучшить свой бизнес в цифровой эпохе.',
    team_title: 'Наша команда',
    team_text: 'ТОО “ByteGenix Software” рад представить наших талантливых коллег, способных воплотить самые амбициозные идеи в инновационные программные решения.',
    team_item1Name: 'Максим',
    team_item1Text1: 'обладает более чем 8-летним опытом разработки и успешно участвовал в создании национальной образовательной базы данных для учебных заведений. Он активно применял свои знания в области React, Redux и REST API для разработки функциональности, такой как система регистрации, аутентификации и управления данными учащихся и преподавателей.',
    team_item1Text2: 'Проект Президентского Молодежного Кадрового Резерва (React, Material UI)',
    team_item1Text3: 'Максим успешно участвовал в разработке проекта Президентского Молодежного Кадрового Резерва, где он использовал свои навыки React и Material UI для создания удобного пользовательского интерфейса. Он внедрил систему записи и тестирования, обеспечивая эффективное взаимодействие пользователей с платформой.',
    team_item1Text4: 'Мобильное Приложение для Такси (Flutter, Yandex Map Kit, MVVM)',
    team_item1Text5: 'такси, используя фреймворк Flutter, Yandex Map Kit и архитектурный паттерн MVVM. Его умение взаимодействовать с REST API и интегрировать сторонние библиотеки позволило создать приложение с сложным функционалом, включая навигацию, маршрутизацию и карту.',
    team_itemName: 'Максим',
    
    team_item2Name: 'Нурдаулет',
    team_item2Text1: '- опытный фронтенд-разработчик, обладающий уникальной дизайнерской способностью. С его более чем 5-летним опытом работы он не только создает функциональные приложения, но и уделяет особое внимание их эстетической стороне. Нурдаулет успешно реализовывал проекты в различных областях, где его дизайнерские способности придают продуктам уникальный и привлекательный вид.',
    team_item2Text2: 'Он обладает глубокими знаниями в области HTML и CSS, что позволяет ему создавать красивые и интуитивно понятные пользовательские интерфейсы. Кроме того, Нурдаулет - эксперт в Angular, что дает ему возможность разрабатывать сложные и динамичные веб-приложения',

    team_item3Name: 'Магзум Нурик',
    team_item3Text1: 'имеет более 3 лет опыта работы, принимая активное участие в сопровождении системы на Java и Angular в АО ЦРТП. Он ответственен за обслуживание и обновление системы, гарантируя ее стабильную и бесперебойную работу. ',
    team_item3Text2: 'Система Сопровождения на Java и Angular (Java, Angular)',
    team_item3Text3: 'Интегрированная Информационная Система (Java, JSF, Oracle):Во время работы в АО "НИТ", Нурик занимал должность Java-программиста и успешно поддерживал интегрированную информационную систему, использующую Java, JSF и Oracle database. Этот опыт дал ему понимание больших проектов и работы с базами данных.',

    team_item4Name: 'Медет',
    team_item4Text1: 'Пришло время рассказать о себе. Я — Медет, IT технологии - моя страсть. С более чем 5 годами опыта в разработке .NET, я создаю инновационные решения, превращая идеи в реальность. ',
    team_item4Text2: 'Моя уникальная способность визуализировать идеи и внимание к деталям дизайна позволяют мне создавать функциональные и привлекательные продукты.',
    team_item4Text3: 'Опыт управления проектами и навыки ведения больших блоков кода позволяют мне эффективно координировать команды и достигать целей.',
    team_item4Text4: 'Я также обучаюсь по разработке и созданию игр на Unreal Engine, что делает меня гибким и адаптивным.',
    team_item4Text5: 'Моя способность навигации в мире идей и управленческая координация помогают мне представлять уникальные концепции и успешно воплощать их.',
    team_item4Text6: 'Я горжусь своим опытом и страстью к инновациям, и готов внести свой след в мир технологий.',

    services_title: 'Наши услуги',
    services_hashtag1: '# Разработка мобильных приложений',
    services_hashtag2: '# Веб-разработка',
    services_hashtag3: '# Искусственный интеллект',
    services_hashtag4: '# Поддержка крупных проектов',
    services_item1Title: 'Разработка мобильных приложений',
    services_item1Text: 'Наши разработчики готовы превратить ваши идеи в красочные и интуитивно понятные мобильные приложения, которые захватят рынок.',
    services_item2Title: 'Веб-разработка',
    services_item2Text: 'Мы создаем веб-сайты и веб-приложения, которые отличаются современным дизайном и выдающейся производительностью.',
    services_item3Title: 'Искусственный интеллект',
    services_item3Text: 'Наши эксперты по искусственному интеллекту помогут вам автоматизировать бизнес-процессы и сделать вашу компанию более конкурентоспособной.',
    services_item4Title: 'Поддержка крупных проектов',
    services_item4Text: 'Мы готовы взять на себя ответственность за ваши крупные проекты, обеспечивая качество и соблюдение сроков.',
    services_item5Title: 'Десктопные приложения',
    services_item5Text: 'Мы разрабатываем надежные и мощные десктопные приложения, которые соответствуют вашим требованиям.',
    services_item6Title: 'Поддержка и доработка старых проектов',
    services_item6Text: 'Гарантируем стабильную работу, соответствие современным требованиям и эффективное обновление функционала.',
  },
  kk: {},
  en: {
    insert_title: 'Development of IT Solutions for Business',
    insert_text: 'We strive to create innovative technological solutions that make life easier and more productive for our clients.',
    insert_btn: 'Contact Us',
    about_title: 'About Us',
    about_text2: 'Welcome to byteGenix software – your reliable partner in the world of technologies! We offer comprehensive solutions in the field of development and technology to help your business thrive and grow.',
    about_lists: 'Our expertise includes:',
    about_list: 'Mobile App Development',
    about_list2: 'Web Development',
    about_list3: 'Artificial Intelligence',
    about_list4: 'Support for Large Projects',
    about_list5: 'Desktop Applications',
    carta_title: 'Address',
    questions_title: 'Have Questions?',
    questions_text: 'If you have any questions or need additional information, our specialists are ready to answer them.',
    questions_btn: 'Contact Us',
    carta_address: 'Mangilik Yel, C4.6 Astana Hub',
    carta_city: 'Astana, Kazakhstan',
    work_title: 'How We Work',
    work_content1Title: 'Consultation and Planning',
    work_content1Text: 'Let’s start by carefully listening to your needs and goals to develop the optimal solution.',
    work_content2Title: 'Design and Development',
    work_content2Text: 'Our experts will begin working on your project, creating a unique and modern design.',
    work_content3Title: 'Testing and Optimization',
    work_content3Text: 'We will thoroughly check every aspect of your project to guarantee its outstanding performance.',
    work_content4Title: 'Launch and Support',
    work_content4Text: 'We support your project after launch, ensuring its reliable operation and updates.',
    what_title: 'Why Choose Us',
    what_content1Title: 'Individual Approach',
    what_content1Text: 'We understand that each project is unique and work on it carefully to achieve your goals.',
    what_content2Title: 'Expert Team',
    what_content2Text: 'Our team consists of experienced developers and designers ready to bring your ideas to life.',
    what_content3Title: 'Modern Design',
    what_content3Text: 'We create stylish and functional solutions that set you apart from the competition.',
    what_content4Title: 'SEO Optimization',
    what_content4Text: 'We ensure your web project is easily discoverable in search engines.',
    what_content5Title: 'Support and Maintenance',
    what_content5Text: 'We don’t just create projects but also ensure their reliable operation and support. Don’t miss the chance to improve your business in the digital era.',
    team_title: 'Our Team',
    team_text: 'TOO “ByteGenix Software” is pleased to introduce our talented colleagues capable of turning the most ambitious ideas into innovative software solutions.',
    team_item1Name: 'Maxim',
    team_item1Text1: 'Has more than 8 years of development experience and successfully participated in creating a national educational database for institutions. Actively applied his knowledge in React, Redux, and REST API for developing features like user registration, authentication, and data management for students and teachers.',
    team_item1Text2: 'Presidential Youth Cadre Reserve Project (React, Material UI)',
    team_item1Text3: 'Maxim successfully contributed to the development of the Presidential Youth Cadre Reserve Project, using his React and Material UI skills to create a user-friendly interface. He implemented a recording and testing system, ensuring efficient user interaction with the platform.',
    team_item1Text4: 'Taxi Mobile App (Flutter, Yandex Map Kit, MVVM)',
    team_item1Text5: 'taxi service using the Flutter framework, Yandex Map Kit, and the MVVM architectural pattern. His ability to interact with REST API and integrate third-party libraries allowed creating an application with advanced functionality, including navigation, routing, and mapping.',
    team_itemName: 'Maxim',

    team_item2Name: 'Nurdaulet',
    team_item2Text1: '- an experienced frontend developer with a unique design ability. With over 5 years of experience, he not only creates functional applications but also pays special attention to their aesthetic aspects. Nurdaulet has successfully implemented projects in various fields, where his design skills give products a unique and appealing look.',
    team_item2Text2: 'He has deep knowledge in HTML and CSS, allowing him to create beautiful and intuitive user interfaces. Additionally, Nurdaulet is an Angular expert, enabling him to develop complex and dynamic web applications.',

    team_item3Name: 'Magzum Nurik',
    team_item3Text1: 'has more than 3 years of experience, actively participating in the maintenance of a Java and Angular system at AO CRTS. He is responsible for servicing and updating the system, ensuring its stable and uninterrupted operation.',
    team_item3Text2: 'Maintenance System on Java and Angular (Java, Angular)',
    team_item3Text3: 'Integrated Information System (Java, JSF, Oracle): While working at AO "NIT," Nurik held the position of Java programmer and successfully maintained an integrated information system using Java, JSF, and Oracle database. This experience gave him an understanding of large projects and working with databases.',

    team_item4Name: 'Medet',
    team_item4Text1: 'It\'s time to tell about myself. I am Medet, and IT technologies are my passion. With more than 5 years of experience in .NET development, I create innovative solutions, turning ideas into reality.',
    team_item4Text2: 'My unique ability to visualize ideas and attention to design details allow me to create functional and attractive products.',
    team_item4Text3: 'Experience in project management and skills in handling large code blocks enable me to coordinate teams effectively and achieve goals.',
    team_item4Text4: 'I also study game development and creating games on Unreal Engine, making me flexible and adaptable.',
    team_item4Text5: 'My ability to navigate the world of ideas and managerial coordination help me conceive unique concepts and successfully implement them.',
    team_item4Text6: 'I am proud of my experience and passion for innovation, ready to make my mark in the world of technology.',

    services_title: 'Our Services',
    services_hashtag1: '# Mobile App Development',
    services_hashtag2: '# Web Development',
    services_hashtag3: '# Artificial Intelligence',
    services_hashtag4: '# Support for Large Projects',
    services_item1Title: 'Mobile App Development',
    services_item1Text: 'Our developers are ready to turn your ideas into colorful and intuitive mobile applications that will capture the market.',
    services_item2Title: 'Web Development',
    services_item2Text: 'We create websites and web applications with modern design and outstanding performance.',
    services_item3Title: 'Artificial Intelligence',
    services_item3Text: 'Our artificial intelligence experts will help you automate business processes and make your company more competitive.',
    services_item4Title: 'Support for Large Projects',
    services_item4Text: 'We are ready to take responsibility for your large projects, ensuring quality and meeting deadlines.',
    services_item5Title: 'Desktop Applications',
    services_item5Text: 'We develop reliable and powerful desktop applications that meet your requirements.',
    services_item6Title: 'Support and Enhancement of Old Projects',
    services_item6Text: 'We guarantee stable operation, compliance with modern requirements, and efficient functionality updates.'
  }
});
