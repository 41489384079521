import React from 'react';
import {Box} from '@mui/material';
import logo from './../../_assets/images/logo.svg'
import {scrollToTop} from './../../_helpers/ScrollToTop'
const Logo = () => {
    return <Box className="logo">
    <img src={logo} className="logo-img" alt="#"  onClick={() => scrollToTop()}/>
</Box>
};

export default Logo;
