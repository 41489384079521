import i18next from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';


const resources = {
  ru: {
    translation: {
    }
  },
  kk: {
    translation: {
    }
  },
  en: {
    translation: {
    }
  }
};

let language = 'ru';
if (window.localStorage.getItem('lang')) {
  language = window.localStorage.getItem('lang') ?? '';
  moment.locale(language);
}

i18next
  .use(initReactI18next)
  .init({ resources, lng: language })
  .then(() => {});

export const addTranslation = (translations) => {
  Object.keys(translations).forEach(lang =>
    i18next.addResources(lang, 'translation', translations[lang])
  );
};
