import { addTranslation } from '../../_helpers/translate';

addTranslation({
  ru: {
    footer_services: 'Наши услуги',
    footer_about: 'О нас',
    footer_working: 'Как мы работаем',
    footer_portfolio: 'Портфолио',
    footer_contacts: 'Контакты',
    footer_text: '@ByteGenix Software'

  },
  kk: {},
  en: {
    footer_services: 'Our Services',
    footer_about: 'About Us',
    footer_working: 'How We Work',
    footer_portfolio: 'Portfolio',
    footer_contacts: 'Contacts',
    footer_text: '@ByteGenix Software'
  }
});
