import React, { useEffect, useState } from 'react';
import {Box} from '@mui/material'
import up from './../../_assets/images/up.svg';
import down from './../../_assets/images/down.svg'
import up2 from './../../_assets/images/up2.svg';
import down2 from './../../_assets/images/down2.svg'
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import i18next from 'i18next';
import moment from 'moment';
const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

const Language = ({type}) => {
    const [currentLang, setCurrentLang] = useState('РУС')
    const langs= [
        { name: 'РУС', code: 'ru' },
        { name: 'EN', code: 'en' }
    ]
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeLang = (language) => {
      moment.locale(language);
      i18next.changeLanguage(language).then(() => {
        localStorage.setItem('lang', language);
      });
    };

    useEffect(() => {
      let aValue = localStorage.getItem('lang');
        langs.forEach((item) => {
          if (item.code === aValue) {
            setCurrentLang(item.name)
          }
        })
    }, []);
    
    return (
        <>
            <Box 
                className="language" 
                onClick={handleClick}
            >
                {
                    type === 'phone' ?
                    <>
                    <p style={{color: '#fff'}}>{currentLang}</p>
                        {
                            open ?
                                <img src={up2} className='language-icon'/> :
                                <img src={down2} className='language-icon'/>  
                        }
                    </> :
                    <>
                    <p>{currentLang}</p>
                        {
                            open ?
                                <img src={up} className='language-icon'/> :
                                <img src={down} className='language-icon'/>  
                        }
                    </>
                    
                }
            </Box>
            <StyledMenu
                style={{zIndex:'9999999'}}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {
                    langs.map((item) => {
                        return (
                            <Box key={item.code}>
                                <MenuItem 
                                    onClick={() => {
                                        handleClose();
                                        handleChangeLang(item.code);
                                        setCurrentLang(item.name)
                                    }} 
                                    disableRipple
                                >
                                    {item.name}
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                            </Box>
                        )
                    })
                }
            </StyledMenu>
        </>
    )
};

export default Language;
