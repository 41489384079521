import React from 'react';
import {Box} from '@mui/material'
import Title from './../../../components/Title/Title'
import bg_design from './../../../_assets/images/bg_design.svg'
import bg5 from './../../../_assets/images/bg5.png'
import team1 from './../../../_assets/images/team1.png'
import team2 from './../../../_assets/images/team2.png'
import team3 from './../../../_assets/images/team3.png'
import team4 from './../../../_assets/images/team4.png'
import './../HomeTranslate'
import { useTranslation } from 'react-i18next';

const Team = () => {
    const { t } = useTranslation();
    return (
        <section className="team">
        <img src={bg_design} className="teamBg"/>
        <img src={bg5} className="teamBg2" />
        <Box className="container team-content">
            <Title title={t('team_title')} />
            <p className="team-text"> {t('team_text')} </p>
            <Box className="team-items">
                <Box className="team-item">
                    <Box className="team-item_photo">
                        <img src={team1} alt="" className="team-item_img" />
                    </Box>
                    <Box className="team-item_content">
                        <p className="team-item_name">{t('team_item1Name')}</p>
                        <p className="team-item_text">
                            <span className="bold">{t('team_itemName')}{' '}</span>
                            {t('team_item1Text1')}
                        </p>
                        <p className="team-item_text"> {t('team_item1Text2')} </p>
                        <p className="team-item_text"> {t('team_item1Text3')} </p>
                        <p className="team-item_text"> {t('team_item1Text4')} </p>
                        <p className="team-item_text"> {t('team_item1Text5')} </p>
                    </Box>
                </Box>
                <Box className="line-block"><span className="line"></span></Box>
                <Box className="team-item">
                    <Box className="team-item_photo">
                        <img src={team2} alt="" className="team-item_img" />
                    </Box>
                    <Box className="team-item_content">
                        <p className="team-item_name">{t('team_item2Name')}</p>
                        <p className="team-item_text">
                            <span className="bold">{t('team_item2Name')}{' '}</span>
                            {t('team_item2Text1')}                                  
                        </p>
                        <p className="team-item_text">{t('team_item2Text2')}</p>
                    </Box>
                </Box>
                <Box className="line-block"><span className="line"></span></Box>
                <Box className="team-item">
                    <Box className="team-item_photo">
                        <img src={team3} alt="" className="team-item_img" />
                    </Box>
                    <Box className="team-item_content">
                        <p className="team-item_name">{t('team_item3Name')}</p>
                        <p className="team-item_text">
                            <span className="bold">{t('team_item3Name')}{' '}</span> 
                            {t('team_item3Text1')}                                                               
                        </p>
                        <p className="team-item_text">{t('team_item3Text2')}</p>
                        <p className="team-item_text">{t('team_item3Text3')}</p>
                    </Box>
                </Box>
                <Box className="line-block"><span className="line"></span></Box>
                <Box className="team-item">
                    <Box className="team-item_photo">
                        <img src={team4} alt="" className="team-item_img" />
                    </Box>
                    <Box className="team-item_content">
                        <p className="team-item_name">{t('team_item4Name')}</p>
                        <p className="team-item_text">
                            <span className="bold">{t('team_item4Name')}{' '}</span>                                                                                                 
                            {t('team_item4Text1')}
                        </p>
                        <p className="team-item_text">{t('team_item4Text2')}</p>
                        <p className="team-item_text">{t('team_item4Text3')}</p>
                        <p className="team-item_text">{t('team_item4Text4')}</p>
                        <p className="team-item_text">{t('team_item4Text5')}</p>
                        <p className="team-item_text">{t('team_item4Text6')}</p>
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box id="working"></Box>
    </section>
    )
};

export default Team;
