import React from 'react';
import Title from './../../../components/Title/Title'
import {Box} from '@mui/material'
import star from './../../../_assets/images/star.svg'
import starRed from './../../../_assets/images/star-red.svg'
import what11 from './../../../_assets/images/what-11.svg'
import what22 from './../../../_assets/images/what-22.svg'
import what33 from './../../../_assets/images/what-33.svg'
import what44 from './../../../_assets/images/what-44.svg'
import what55 from './../../../_assets/images/what-55.svg'
import './../HomeTranslate'
import { useTranslation } from 'react-i18next';

const What = () => {
    const { t } = useTranslation();
    return (
        <section className="what" id='what'>
        <Box className="container">
            <Title title={t('what_title')} />
            <Box className="what-items">
                <Box className="what-item">
                    <img src={star} className="what-star" />
                    <img src={starRed} className="what-star2" />
                    <Box className="what-top">
                        <Box className="what-icon what-icon__color1">
                            <img src={what11} className="what-img"/>
                        </Box>
                        <p className="what-title">{t('what_content1Title')}</p>
                    </Box>
                    <Box className="what-content">
                        <p className="what-text">{t('what_content1Text')}</p>
                    </Box>
                </Box>
                <Box className="what-item">
                    <img src={star} className="what-star" />
                    <img src={starRed} className="what-star2" />
                    <Box className="what-top">
                        <Box className="what-icon what-icon__color2">
                            <img src={what22} className="what-img"/>
                        </Box>
                        <p className="what-title">{t('what_content2Title')}</p>
                    </Box>
                    <Box className="what-content">
                        <p className="what-text">{t('what_content2Text')}</p>
                    </Box>
                </Box>
                <Box className="what-item">
                    <img src={star} className="what-star" />
                    <img src={starRed} className="what-star2" />
                    <Box className="what-top">
                        <Box className="what-icon what-icon__color3">
                            <img src={what33} className="what-img"/>
                        </Box>
                        <p className="what-title">{t('what_content3Title')}</p>
                    </Box>
                    <Box className="what-content">
                        <p className="what-text">{t('what_content3Text')}</p>
                    </Box>
                </Box>
                <Box className="what-item">
                    <img src={star} className="what-star" />
                    <img src={starRed} className="what-star2" />
                    <Box className="what-top">
                        <Box className="what-icon what-icon__color4">
                            <img src={what44} className="what-img"/>
                        </Box>
                        <p className="what-title">{t('what_content4Title')}</p>
                    </Box>
                    <Box className="what-content">
                        <p className="what-text">{t('what_content4Text')}</p>
                    </Box>
                </Box>
                <Box className="what-item">
                    <img src={star} className="what-star" />
                    <img src={starRed} className="what-star2" />
                    <Box className="what-top">
                        <Box className="what-icon what-icon__color5">
                            <img src={what55} className="what-img"/>
                        </Box>
                        <p className="what-title"> {t('what_content5Title')} </p>
                    </Box>
                    <Box className="what-content">
                        <p className="what-text"> {t('what_content5Text')} </p>
                    </Box>
                </Box>
            </Box>
        </Box>
    </section>
    )
};

export default What;
