import './App.css';
import RouterProps from './_helpers/PouterProps'

function App() {
  return (
    <RouterProps/>
  );
}

export default App;
