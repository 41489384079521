import React from 'react';
import {Box} from '@mui/material';
import Logo from './../Logo/Logo';
import email from './../../_assets/images/email.svg'
import call from './../../_assets/images/call.svg'
import whatsapp from './../../_assets/images/whatsapp.png'
import telegram from './../../_assets/images/telegram.png'
import './FooterTranslate'
import { useTranslation } from 'react-i18next';
import {INFO} from './../../_helpers/Constants'

const Footer = () => {
    const { t } = useTranslation();
    let date = new Date();
    return (
        <footer className="footer">
        <Box className="container">
            <Box className="footer-items">
                <Box className="footer-item">
                    <Box className="footer-logo">
                        <Logo />
                    </Box>
                    <ul className="footer-lists">
                        <li className="footer-list"><a href="#services">{t('footer_services')}</a></li>
                        <li className="footer-list"><a href="#about">{t('footer_about')}</a></li>
                        <li className="footer-list"><a href="#working">{t('footer_working')}</a></li>
                        <li className="footer-list"><a href="#portfolio">{t('footer_portfolio')}</a></li>
                        <li className="footer-list"><a href="#contacts">{t('footer_contacts')}</a></li>
                    </ul>
                </Box>
                <Box className="footer-item">
                    <ul className="footer-contacts">
                        <li className="footer-contact"><a href={`mailto:${INFO.email}`} className="footer-media_link">
                            <img src={email} className="footer-contact_img"/>{INFO.email}</a>
                        </li>
                        <li className="footer-contact">
                            <a href={`tel:${INFO.tel}`} className="footer-media_link"><img src={call} className="footer-contact_img"/>{INFO.tel}</a>
                            </li>
                        <li className="footer-contact_media">
                            <a href={INFO.wa} target="_blank" className="footer-media"><img src={whatsapp} className="footer-media_img" />whatsapp</a>
                            <a href={INFO.tlgg} className="footer-media"><img src={telegram} className="footer-media_img" />telegram</a>
                        </li>
                    </ul>
                </Box>
            </Box>
            <Box className="footer-bottom">
                <p className="footer-info">{t('footer_text')} {date.getFullYear()}</p>
            </Box>
        </Box>
    </footer>
    )
};

export default Footer;
