import React from 'react';
import Title from './../../../components/Title/Title';
import {Box} from '@mui/material';
import bg6 from './../../../_assets/images/bg6.webp'
import work1 from './../../../_assets/images/1-work.png'
import work2 from './../../../_assets/images/2-work.png'
import work3 from './../../../_assets/images/3-work.png'
import work4 from './../../../_assets/images/4-work.png'
import './../HomeTranslate'
import { useTranslation } from 'react-i18next';

const Work = () => {
    const { t } = useTranslation();
    return (
        <section className="work">
        <img src={bg6} className="workBg"/>
        <Box className="container">
            <Title title={t('work_title')} />
            <Box className="work-items">
                <Box className="work-item">
                    <Box className="work-photo">
                        <img src={work1} className="work-item_img"/>
                    </Box>
                    <Box className="work-content">
                        <p className="work-content_name">{t('work_content1Title')}</p>
                        <p className="work-content_text">{t('work_content1Text')}</p>
                    </Box>
                </Box>
                <Box className="work-item">
                    <Box className="work-photo">
                        <img src={work2} className="work-item_img"/>
                    </Box>
                    <Box className="work-content">
                        <p className="work-content_name">{t('work_content2Title')}</p>
                        <p className="work-content_text">{t('work_content2Text')}</p>
                    </Box>
                </Box>
                <Box className="work-item">
                    <Box className="work-photo">
                        <img src={work3} className="work-item_img"/>
                    </Box>
                    <Box className="work-content">
                    <p className="work-content_name">{t('work_content3Title')}</p>
                        <p className="work-content_text">{t('work_content3Text')}</p>
                    </Box>
                </Box>
                <Box className="work-item">
                    <Box className="work-photo">
                        <img src={work4} className="work-item_img"/>
                    </Box>
                    <Box className="work-content">
                        <p className="work-content_name">{t('work_content4Title')}</p>
                        <p className="work-content_text">{t('work_content4Text')}</p>
                    </Box>
                </Box>
            </Box>
        </Box>
    </section>
    )
};

export default Work;
