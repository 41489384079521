import React, {useEffect, useState} from 'react';
import { Box } from '@mui/material';
import Logo from './../Logo/Logo';
import whatsapp from './../../_assets/images/whatsapp.png'
import email from './../../_assets/images/email000.svg'
import call from './../../_assets/images/call.svg'
import MenuIcon from '@mui/icons-material/Menu';
import call000 from './../../_assets/images/call000.svg'
import telegram from './../../_assets/images/telegram.png'
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Language from './../Language/Language';
import { useTranslation } from 'react-i18next';
import './HeaderTranslate'
import {INFO} from './../../_helpers/Constants'
const StyledMenu = styled((props) => (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

const Header = () => {
    const { t } = useTranslation();
    const [isFixed, serIsFixed] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect((e) => {
        window.addEventListener('scroll', function(event){
            if (window.pageYOffset >= window.outerHeight - 500) {
                serIsFixed(true)
            } else {
                serIsFixed(false)
            }
        }); 
    })

    return (
        <header>
            <Box className="header-info">
                <Box className="container">
                    <Box className="header-top2">
                        <Box className="header-top_logo">
                            <Logo />
                        </Box>
                        <Box className="header-items">
                            <a href={`mailto:${INFO.email}`} className="header-item"> 
                                <img src={email} className="header-item_icon" alt="" />
                                {INFO.email}
                            </a>
                            <Language />
                            <a href={`tel:${INFO.tel}`} className="header-item header-item_tel"> 
                                <img src={call000} className="header-item_icon" alt="" /> 
                                {INFO.tel} 
                            </a>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={isFixed ? 'headerFixed' : "header-top"}>
                <Box className="container">
                    <Box className="header-flex">
                        <Box className="header-bottom_logo">
                            <Logo />
                        </Box>
                        <Box className="menu">
                            <ul className="menu-items">
                                <li className="menu-item"><a href="#services">{t('header_services')}</a></li>
                                <li className="menu-item"><a href="#about">{t('header_about')}</a></li>
                                <li className="menu-item"><a href="#working">{t('header_working')}</a></li>
                                <li className="menu-item"><a href="#portfolio">{t('header_portfolio')}</a></li>
                                <li className="menu-item"><a href="#contacts">{t('header_contacts')}</a></li>
                            </ul>
                        </Box>
                        <Box className="header-tel">
                            <Box>
                                <a href={`tel:${INFO.tel}`} className="header-tel_number">
                                    <img src={call} alt="#" className="header-tel_logo"/>
                                    <p>{INFO.tel}</p>
                                </a>
                                <Box style={{display: 'flex', gap: '5px'}}>
                                    <Box className="header-whatsapp">
                                        <img src={whatsapp} alt="#" className="header-whatsapp_icon" />
                                        <a href={INFO.wa} target="_blank" className="header-whatsapp_text">WhatsApp</a>
                                    </Box>
                                    <Box className="header-whatsapp">
                                        <img src={telegram} alt="#" className="header-whatsapp_icon" />
                                        <a href={INFO.tlgg} target="_blank" className="header-whatsapp_text">Telegram</a>
                                    </Box>
                               </Box>
                            </Box>
                        </Box>
                        <Box className="phone_menu">
                            <Language type='phone'/>
                            <a href={INFO.tlgg}>
                                <img src={telegram} alt="#" className='phone_menu-icon'/>
                            </a>
                            <a href={INFO.wa} target="_blank">
                                <img src={whatsapp} alt="#" className="phone_menu-icon" />
                            </a>
                            <a href="tel:+77075998393">
                                <img src={call} alt="#" className='phone_menu-icon'/>
                            </a>
                            <MenuIcon 
                                style={{color: '#fff', fontSize: '30px'}}
                                onClick={handleClick}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <StyledMenu            
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                style={{zIndex:'9999999'}}
            >
                <MenuItem onClick={handleClose} disableRipple>
                    <a href="#services" style={{width: '100%', maxWidth: '100%'}}>{t('header_services')}</a>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple>
                    <a href="#about" style={{width: '100%', maxWidth: '100%'}}>{t('header_about')}</a>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple>
                    <a href="#working" style={{width: '100%', maxWidth: '100%'}}>{t('header_working')}</a>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple>
                    <a href="#portfolio" style={{width: '100%', maxWidth: '100%'}}>{t('header_portfolio')}</a>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple>
                    <a href="#contacts" style={{width: '100%', maxWidth: '100%'}}>{t('header_contacts')}</a>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
            </StyledMenu>            
        </header>
    )
};

export default Header;
