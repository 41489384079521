import React from 'react';
import Title from './../../../components/Title/Title'
import {Box} from '@mui/material'
import nedorogoBanner from './../../../_assets/images/nedorogo-banner-l.jpg'
import './../HomeTranslate'
import { useTranslation } from 'react-i18next';
import {INFO} from './../../../_helpers/Constants'

const Questions = () => {
    const { t } = useTranslation();
    return (
        <section className="questions">
            <Box className="container">
                <Box className="questions-items">
                    <Box className="questions-item">
                        <Box style={{marginTop: '-40px'}}>
                            <Title title={t('questions_title')} />
                        </Box>
                        <p className="questions-text"> {t('questions_text')} </p>
                        <Box className='questions-btn'>
                            <a href={INFO.wa} target="_blank" className="button">
                                {t('questions_btn')}
                            </a>
                        </Box>
                    </Box>
                    <Box className="questions-item">
                        <img src={nedorogoBanner} className="questions-item_img" />
                    </Box>
                </Box>
            </Box>
        </section>
    )
};

export default Questions;
