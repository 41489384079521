import React from 'react';
import Title from './../../../components/Title/Title'
import {Box} from '@mui/material'
import bg4 from './../../../_assets/images/bg4.png'
import Icon11 from './../../../_assets/images/11-icon.svg'
import Icon12 from './../../../_assets/images/12-icon.svg'
import Icon13 from './../../../_assets/images/13-icon.svg'
import Icon14 from './../../../_assets/images/14-icon.svg'
import Icon15 from './../../../_assets/images/15-icon.svg'
import oNas from './../../../_assets/images/oNas.png'
import './../HomeTranslate'
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();
    return (
        <section className="about">
            <img src={bg4} className="aboutBg" />
            <Box className="container">
                <Box className="about-items">
                    <Box className="about-item" style={{backgroundColor: '#fff'}}>
                        <Title title={t('about_title')} />
                        <p className="about-text">{t('about_text2')}</p>
                        <p className="about-text bold">{t('about_lists')}</p>
                        <ul className="about-lists">
                            <li className="about-list"><img src={Icon11} className="about-list_img" /> <span className="about-list_text">{t('about_list')}</span></li>
                            <li className="about-list"><img src={Icon12} className="about-list_img" /> <span className="about-list_text">{t('about_list2')}</span></li>
                            <li className="about-list"><img src={Icon13} className="about-list_img" /> <span className="about-list_text">{t('about_list3')}</span></li>
                            <li className="about-list"><img src={Icon14} className="about-list_img" /> <span className="about-list_text">{t('about_list4')}</span></li>
                            <li className="about-list"><img src={Icon15} className="about-list_img" /> <span className="about-list_text">{t('about_list5')}</span></li>
                        </ul>
                    </Box>
                    <Box className="about-item">
                        <img src={oNas} alt='#' className='about-item_bg'/>
                    </Box>
                </Box>
            </Box>
            <Box id="portfolio"></Box>
        </section>
    )
};

export default About;
