import React from 'react';
import {Box} from '@mui/material';
import bg3 from './../../../_assets/images/bg3.png'
import imgConnect from './../../../_assets/images/img-connect.webp'
import './../HomeTranslate'
import { useTranslation } from 'react-i18next';
import {INFO} from './../../../_helpers/Constants'

const Insert = () => {
    const { t } = useTranslation();
    return (
        <section className="insert">
            <img src={bg3} className="insertBg"/>
            <Box className="container">
                <Box className="insert-items">
                    <Box className="insert-item">
                        <Box>
                            <h1 className="insert-title">{t('insert_title')}</h1>
                            <p className="insert-text">{t('insert_text')}</p>    
                            <div className="insert-btn">
                                <a href={INFO.wa} target="_blank" className="button">
                                    {t('insert_btn')}
                                </a>
                            </div>
                        </Box>
                    </Box>
                    <Box className="insert-item">
                        <img src={imgConnect} alt="" className="insert-item_bg" />
                    </Box>
                </Box>
            </Box>
            <Box id="services"></Box>
        </section>
    )
};

export default Insert;
