import React from 'react';
import Title from './../../../components/Title/Title'
import {Box} from '@mui/material'
import play from './../../../_assets/images/play.svg'
import './../HomeTranslate'
import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t } = useTranslation();
    return (
        <section className="services">
        <Box className="container">
            <Title title={t('services_title')} />
            <ul className="services-hashtags">
                <li className="services-hashtag">{t('services_hashtag1')}</li>
                <li className="services-hashtag">{t('services_hashtag2')}</li>
                <li className="services-hashtag">{t('services_hashtag3')}</li>
                <li className="services-hashtag">{t('services_hashtag4')}</li>
            </ul>
            <Box className="services-items">
                <Box className="services-item">
                    <p className="services-item_title">{t('services_item1Title')}</p>
                    <p className="services-item_text">{t('services_item1Text')}</p>
                    <img src={play} alt="#" className="services-item_icon"/>
                </Box>
                <Box className="services-item">
                    <p className="services-item_title">{t('services_item2Title')}</p>
                    <p className="services-item_text">{t('services_item2Text')}</p>
                    <img src={play} alt="#" className="services-item_icon"/>
                </Box>
                <Box className="services-item">
                    <p className="services-item_title">{t('services_item3Title')}</p>
                    <p className="services-item_text">{t('services_item3Text')}</p>
                    <img src={play} alt="#" className="services-item_icon"/>
                </Box>
                <Box className="services-item">
                    <p className="services-item_title">{t('services_item4Title')}</p>
                    <p className="services-item_text">{t('services_item4Text')}</p>
                    <img src={play} alt="#" className="services-item_icon"/>
                </Box>
                <Box className="services-item">
                    <p className="services-item_title">{t('services_item5Title')}</p>
                    <p className="services-item_text">{t('services_item5Text')}</p>
                    <img src={play} alt="#" className="services-item_icon"/>
                </Box>
                <Box className="services-item">
                    <p className="services-item_title">{t('services_item6Title')}</p>
                    <p className="services-item_text">{t('services_item6Text')}</p>
                    <img src={play} alt="#" className="services-item_icon"/>
                </Box>
            </Box>
        </Box>
        <Box id="about"></Box>
    </section>
    )
};

export default Services;
