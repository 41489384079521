import { addTranslation } from '../../_helpers/translate';

addTranslation({
  ru: {
    header_services: 'Наши услуги',
    header_about: 'О нас',
    header_working: 'Как мы работаем',
    header_portfolio: 'Портфолио',
    header_contacts: 'Контакты'
  },
  kk: {},
  en: {
    header_services: 'Our Services',
    header_about: 'About Us',
    header_working: 'How We Work',
    header_portfolio: 'Portfolio',
    header_contacts: 'Contacts'
  }
});
