import React from 'react';
import Insert from './Section/Insert';
import Services from './Section/Services';
import Work from './Section/Work';
import What from './Section/What';
import Questions from './Section/Questions';
import Team from './Section/Team';
import About from './Section/About';
import Carta from './Section/Carta';

const HomePage = () => (
    <main>
        <Insert />
        <Services />
        <About />
        <Team />
        <Work />
        <What />
        <Questions />
        <Carta />
    </main>
);

export default HomePage;