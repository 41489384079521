import React from 'react';
import {Box} from '@mui/material'

const Title = ({title}) => (
    <Box className="title flex alignCenter">
        <Box className="slash">
            <span>&#47;</span>
            <span>&#47;</span>
        </Box>
        <h2>{title}</h2>
    </Box>
);

export default Title;
